<template>
    <multi-select
        ref="select"
        placeholder="control.status_selected"
        :inputHandler="selectedStatuses"
        :selected="selectedStatusIds"
        :service="statusService"
        name="statuses"
        :size="size"
        @onOptionsLoad="onLoad"
    >
    </multi-select>
</template>

<script>
    import MultiSelect from '@/components/MultiSelect';
    import StatusService from '@/services/resource/status.service';

    const localStorageKey = 'ust.local.storage.status_select';

    export default {
        name: 'StatusSelect',
        components: {
            MultiSelect,
        },
        props: {
            size: {
                type: String,
                default: 'normal',
            },
            value: {
                required: false,
                type: Array,
            },
            localStorageKey: {
                type: String,
                default: 'user-select.statuses',
            },
        },
        data() {
            const selectedStatusIds =
                this.value !== null ? this.value : JSON.parse(localStorage.getItem(localStorageKey));
            return {
                statusService: new StatusService(),
                selectedStatusIds,
            };
        },
        methods: {
            onLoad(allSelectOptions) {
                const allStatusIds = allSelectOptions.map(option => option.attributes.id);
                const filteredStatuses = allSelectOptions.filter(
                    status => !['canceled', 'archived', 'completed'].includes(status.attributes.state_name),
                );
                const filteredStatusesIds = filteredStatuses.map(option => option.attributes.id).map(String);

                // Select all filtered options if storage is empty
                if (!localStorage.getItem(localStorageKey)) {
                    this.selectedStatusIds = filteredStatusesIds;
                    localStorage.setItem(localStorageKey, JSON.stringify(this.selectedStatusIds));
                    this.$emit('change', this.selectedStatusIds);
                    this.$nextTick(() => this.$emit('loaded'));
                    return;
                }

                // Remove options that no longer exist
                const existingStatusIds = this.selectedStatusIds.filter(statusId => allStatusIds.includes(statusId));

                if (this.selectedStatusIds.length > existingStatusIds.length) {
                    this.selectedStatusIds = existingStatusIds;
                    localStorage.setItem(localStorageKey, JSON.stringify(this.selectedStatusIds));
                }

                this.$emit('change', this.selectedStatusIds);
                this.$nextTick(() => this.$emit('loaded'));
            },

            selectedStatuses(values) {
                this.selectedStatusIds = values;
                localStorage.setItem(localStorageKey, JSON.stringify(this.selectedStatusIds));
                this.$emit('change', values);
            },
        },

        watch: {
            value(ids) {
                this.selectedStatusIds = ids;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .at-select {
        .theme-dark & {
            color: #c4c4cf;
        }

        &__selection {
            .theme-dark & {
                background-color: #555;
            }
        }
        &__list {
            .theme-dark & {
                color: #c4c4cf;
                background-color: #555;
            }
        }
        &__option {
            .theme-dark & {
                &:hover {
                    background-color: #333 !important;
                    color: #ffa500;
                }
            }
        }
    }
</style>
