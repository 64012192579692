import ResourceService from '@/services/resource.service';
import axios from 'axios';
import { serialize } from '@/utils/url';

export default class StatusService extends ResourceService {
    async getAll(config = {}) {
        return await axios.get('api/v1/statuses', config);
    }

    getItemRequestUri(id) {
        return `statuses/show?${serialize({ id })}`;
    }

    getItem(id) {
        return axios.get(this.getItemRequestUri(id));
    }

    save(data, isNew = false) {
        return axios.post(`statuses/${isNew ? 'create' : 'edit'}`, data);
    }

    deleteItem(id) {
        return axios.post('statuses/remove', { id });
    }

    getOptionLabelKey() {
        return 'name';
    }

    getOptionList() {
        return this.getAll().then(({ data }) =>
            data.data.map(option => ({
                value: option.id,
                label: option[this.getOptionLabelKey()],
                active: option.active,
            })),
        );
    }

    getWithFilters(filters, config = {}) {
        return axios.post('statuses/list', filters, config);
    }
}
